@import '../../styles/_variables.scss';

.Sidebar {
  position: sticky;
  top: 0;
  padding-top: 20px;

  &-item {
    a {
      cursor: pointer;
      padding: 10px 15px;
      border-left: 3px solid $theme-lightest-gray;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $theme-dark-gray;

      transition: color 200ms, border 200ms;

      &:hover {
        color: $theme-primary;
      }

      &.active {
        color: $theme-primary;
        border-color: $theme-primary;
      }
    }
  }

  .check-icon {
    color: $theme-lighter-gray;
    border-radius: 50%;
    border: 1px solid $theme-lighter-gray;
    padding: 2px;
    width: 1em;

    transition: color 200ms, border 200ms;

    &.is-completed {
      color: $theme-success-color;
      border-color: $theme-success-color;
    }
  }
}

.ee_form-row__descriptor {
  display: none !important;
}

.form-section {
  padding-bottom: 130px;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
}

.show-descriptor {
  .ee_form-row__descriptor {
    display: block !important;
    width: 110px !important;
    margin-right: 40px !important;
  }

  .ee_form-row__messages {
    padding-left: 130px !important;
  }
}

.error-without-padding {
  .ee_form-row__messages {
    padding-left: 0 !important;
  }
}
